import 'scss/pages/about-us.scss';
import React from 'react';
import { Trans } from '@lingui/macro';
import Layout from 'components/Layout';
import Seo from 'components/SEO';
import Image from 'components/atoms/Image/Image';
import {Link} from 'gatsby';
import {List, ListItem} from 'components/atoms/List/List';
import {careerContactFormProps, Perks} from "../../page-components/careers/common";

const TechnicalSalesSpecialist = () => {
  return (
      <Layout contactFormProps={careerContactFormProps} >
        <Seo title="Technical Sales Specialist at YanchWare"/>
        <section className="hero-wrapper">
          <div className="hero">
            <div className="hero__content">
              <h1 className="hero__title">
                <Trans>Technical Sales Specialist @ YanchWare</Trans>
              </h1>
              <p className="hero__text">
                <Trans>
                  As a Technical Sales Specialist at YanchWare, you are the driving force behind our business growth,
                  specializing in selling our cutting-edge SaaS products to large enterprises.
                </Trans>
              </p>
              <p className="hero__text">
                <Trans>
                  This pivotal role not only focuses on achieving sales targets but also on building strong,
                  long-lasting customer relationships. With success, you will have the opportunity to advance to a
                  Chief Commercial Officer (CCO) role and earn share options, reflecting your critical contribution
                  to our company.
                </Trans>
              </p>
              <Link to="/contact-us" className="bg-green-900 w-[190px] mt-m py-xs px-m rounded-[10px] flex justify-between">
                <span className="text-transparent bg-clip-text bg-hero-text">
                  <Trans>Apply now</Trans>
                </span>
                <Image name="arrow-icon" className="fill-icon mr-s" />
              </Link>
            </div>
            <Image name="careers/technical-sales-specialist" className="hero__image"/>
          </div>
        </section>

        <section className="container tac">
          <h2 className="color-primary">
            <Trans>About the role</Trans>
          </h2>

          <div className="content-blocks content-blocks--reverse">
            <div className="content-block">
              <Image
                  name="about-us-page/customer-success"
                  className="content-block__image"
              />
              <div className="content-block__info">
                <h2>
                  <Trans>Responsibilities:</Trans>
                </h2>
                <p>
                  <Trans>
                    In this key role, your primary responsibilities will include:
                  </Trans>
                </p>
                <p>
                  <List>
                    <ListItem>
                      <Trans>
                        Sales Execution: Identify potential business opportunities and develop tailored sales strategies
                        for large enterprise clients. Lead negotiations and close deals, ensuring alignment with the
                        company’s strategic goals.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Relationship Management: Cultivate and maintain strong relationships with key decision-makers
                        within target industries. Understand customer challenges and deliver solutions that meet their
                        business needs.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Market Analysis: Conduct detailed market research to stay ahead of industry trends and
                        competitor developments. Use insights to refine sales strategies and approaches.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Collaborative Teamwork: Work closely with the marketing, product, and technical teams to ensure
                        a cohesive approach to customer acquisition and satisfaction.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Sales Targets: Consistently meet or exceed sales targets, contributing to the company's overall
                        revenue goals.
                      </Trans>
                    </ListItem>
                  </List>
                </p>
              </div>
            </div>
            <div className="content-block">
              <Image
                  name="about-us-page/about-us-hero"
                  className="content-block__image"
              />
              <div className="content-block__info">

                <h2>
                  <Trans>Success Criteria:</Trans>
                </h2>
                <p>
                  <Trans>
                    Your success will be measured by the following criteria:
                  </Trans>
                </p>
                <p>
                  <List>
                    <ListItem>
                      <Trans>
                        Sales Performance: Achieve and surpass sales targets, demonstrating a direct impact on our
                        company’s growth.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Customer Satisfaction: Establish a track record of excellent customer relations and high
                        satisfaction rates, leading to repeat business and long-term partnerships.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Strategic Impact: Contribute to the strategic direction of the sales department, setting a
                        foundation for potential advancement to CCO.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Market Penetration: Successfully introduce our SaaS products into new markets and sectors,
                        expanding our customer base and market share.
                      </Trans>
                    </ListItem>
                  </List>
                </p>
              </div>
            </div>
            <div className="content-block">
              <Image
                  name="careers/whoareu"
                  className="content-block__image"
              />
              <div className="content-block__info">
                <h2>
                  <Trans>Who are you:</Trans>
                </h2>
                <p>
                  <Trans>
                    You are a dynamic and driven sales professional with a passion for technology and a proven track
                    record in SaaS sales:
                  </Trans>
                </p>
                <p>
                  <List>
                    <ListItem>
                      <Trans>
                        Experienced in SaaS Sales: You have extensive experience selling technical SaaS products to
                        large enterprises and are adept at navigating complex sales cycles.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Strategic Thinker: Skilled in developing strategic sales plans and effectively executing them
                        to win business in competitive environments.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Customer-Focused: Exceptional at building relationships, understanding client needs, and
                        delivering solutions that align with their objectives.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Ambitious Leader: Motivated by career growth opportunities, aiming to advance to executive
                        roles and take on increasing responsibilities.
                      </Trans>
                    </ListItem>
                  </List>
                </p>
              </div>
            </div>
          </div>
          <Perks/>
        </section>
      </Layout>
  );
};

export default TechnicalSalesSpecialist;
